import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  border-top: 2px solid ${props => props.theme.colors.text};
  flex-grow: 1;

  h1 {
    padding: 30px 15px 0;
    line-height: 1em;
    font-size: ${props => props.theme.fontSizes[4]}px;
    @media (min-width: ${props => props.theme.responsive.small}) {
      font-size: ${props => props.theme.fontSizes[5]}px;
    }
    @media (min-width: ${props => props.theme.responsive.medium}) {
      font-size: ${props => props.theme.fontSizes[7]}px;
      padding: 30px 30px 0;
    }
    @media (min-width: ${props => props.theme.responsive.large}) {
      font-size: ${props => props.theme.fontSizes[9]}px;
    }
  }
`

const Container = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Container
