import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  border-top: 2px solid ${props => props.theme.colors.text};
  flex: 100%;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 270px;
    max-width: 270px;
    border-right: 2px solid #000;
    padding: 30px 60px;
    display: flex;
  }
  @media (min-width: ${props => props.theme.responsive.large}) {
    flex: 0 0 350px;
    max-width: 350px;
  }
`

const Container = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Container
